/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import {
  CopyIcon,
  EditInfoIcon,
  EllipsisIcon,
  ExportIcon,
  Manager,
  Popover,
  Reference,
  SendIcon
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import { useCallback, useState } from 'react'
import {
  ActionsButtonContainer,
  ActionsContainer,
  popoverCss
} from './QuotationsActions.style'

export const QuotationsActions = ({
  isUnreserved,
  isExpired,
  onResend,
  onEdit,
  onClone,
  onExport
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen])

  const onAction = useCallback(
    action => {
      toggle()
      action()
    },
    [toggle]
  )

  return (
    <>
      <Manager>
        <Reference>
          {({ ref }) => (
            <ActionsButtonContainer
              ref={ref}
              onClick={toggle}
              className='options'
            >
              <EllipsisIcon />
            </ActionsButtonContainer>
          )}
        </Reference>
        {isOpen && (
          <Popover customCss={[popoverCss]} position='left'>
            <ActionsContainer>
              <button onClick={() => onAction(onExport)}>
                <ExportIcon /> Exportar em PDF
              </button>
              {isUnreserved && (
                <>
                  {isExpired && (
                    <button onClick={() => onAction(onResend)}>
                      <SendIcon /> Reenviar roteiro
                    </button>
                  )}
                  <button onClick={() => onAction(onEdit)}>
                    <EditInfoIcon /> Editar roteiro
                  </button>
                </>
              )}
              <button onClick={() => onAction(onClone)}>
                <CopyIcon /> Duplicar roteiro
              </button>
            </ActionsContainer>
          </Popover>
        )}
      </Manager>
    </>
  )
}
