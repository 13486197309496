/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useMemo, useState } from 'react'
import {
  Card,
  H3,
  TableBodyCell,
  TableBodyRow,
  TooltipOnHover,
  SortRightIcon,
  useToast,
  Row
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import { formatMoney } from '@bonitour/common-functions'
import { parseTitleProp, parseDate } from 'utils/utils'
import { quotationsStatusData } from '../utils/status'

import {
  statusStyle,
  quotationDetailsStyle,
  peopleRowStyle,
  resetPosition,
  arrowIconStyle,
  checkBoxContainer,
  smallFont,
  nameStyle,
  smallTitleCss,
  limitedTruncateStyle
} from './QuotationsListItem.style'
import { UNRESERVED } from './io/Quotations.io'
import { usePermission } from 'Shared/contexts/Permissions'
import { QuotationExtraServiceList } from './QuotationExtraServicesList'
import { SafeDate } from '@binamik/js-providers'
import { QuotationsActions } from './QuotationActions'
import { encodeItineraryId } from '../AgentComponent/io/agentItineraries.io'
import { formatBrazilianPhoneNumber } from 'utils/parsers/formatBrazilianPhoneNumber'

export const QuotationsListItem = ({
  quotation,
  onExport,
  onResend = _ => console.error('On Resend not implemented')
}) => {
  const { reservation_code: reservationCode = '' } = quotation.reservation
  const {
    status = '',
    tours: activities = {},
    transports = {},
    id: quotationId = '',
    extra_services: extraServices = []
  } = quotation
  const {
    agent_name: agentName = '',
    client_name: clientName = '',
    client_email: clientEmail = '',
    client_phone: clientPhone = '',
    created_at: createdAt = '',
    code = ''
  } = quotation.quotation

  const quotationRegionName = useMemo(
    () =>
      quotation?.custom_regions?.map(({ name }) => name).join(', ') ||
      quotation?.region?.name ||
      '--',
    [quotation]
  )

  const [isOpen, setIsOpen] = useState(false)

  const { allowed: canEdit } = usePermission({
    permission: 'xpert_agent',
    action: 'update'
  })

  const { add: addToast } = useToast()

  const onEdit = useCallback(
    quotation => {
      if (!canEdit) {
        return addToast(
          'Usuário sem permissão de escrita/atualização como agente'
        )
      }
      const newTab = window.open(
        `${quotation?.domain}/cart?itinerary=${quotation?.id}`,
        '_blank',
        'noopener'
      )
      if (newTab && typeof newTab.focus === 'function') {
        newTab.focus()
      }
    },
    [canEdit, addToast]
  )

  const onClone = useCallback(
    quotationId => {
      if (!canEdit) {
        return addToast(
          'Usuário sem permissão de escrita/atualização como agente'
        )
      }
      encodeItineraryId({
        domain: quotation?.domain,
        id: quotationId
      }).then(({ parsedId }) => {
        const newTab = window.open(
          `${quotation?.domain}/clone/${parsedId}`,
          '_blank',
          'noopener'
        )
        if (newTab && typeof newTab.focus === 'function') {
          newTab.focus()
        }
      })
    },
    [canEdit, quotation?.domain, addToast]
  )

  const segments = useMemo(
    () =>
      quotationsStatusData.find(({ name }) => {
        if (
          new Date(quotation?.travel_date?.start_date || 0) < new Date() &&
          name !== 'reserved' &&
          name !== 'confirmed' &&
          name !== 'checked_in'
        ) {
          return name === 'expired'
        } else if (
          quotation.quotation.opened_at !== undefined &&
          status === UNRESERVED
        ) {
          return name === 'opened'
        } else {
          return name === status
        }
      }) || quotationsStatusData[0],
    [status, quotation]
  )

  const parsedReservationCode = useMemo(
    () => (status !== UNRESERVED ? reservationCode : '--'),
    [status, reservationCode]
  )

  return (
    <>
      <TableBodyRow css={smallFont}>
        <TruncateCell text={agentName}>
          <CheckboxQuotation
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            name={agentName}
          />
        </TruncateCell>
        <TruncateCell text={clientName} truncateStyle={limitedTruncateStyle} />
        <TruncateCell text={clientEmail} truncateStyle={limitedTruncateStyle} />
        <TruncateCell text={parseDate(createdAt)} />
        <TruncateCell key={segments?.name} text={segments?.alias}>
          <span key={segments?.name} css={statusStyle(segments?.color)}>
            {segments?.alias}
          </span>
        </TruncateCell>
        <TruncateCell text={code} />
        <TruncateCell text={parsedReservationCode} />
        <TruncateCell>
          <QuotationsActions
            isExpired={segments?.name === 'expired'}
            isUnreserved={quotation.status === UNRESERVED}
            onResend={() => onResend(quotationId)}
            onEdit={() => onEdit(quotation)}
            onClone={() => onClone(quotationId)}
            onExport={() => onExport(quotationId)}
          />
        </TruncateCell>
      </TableBodyRow>
      {isOpen && (
        <TableBodyCell colSpan={8}>
          <QuotatonDetails
            clientPhone={formatBrazilianPhoneNumber(clientPhone)}
            quotationRegionName={quotationRegionName}
            activities={activities}
            transports={transports}
            extraServices={extraServices}
            quotationReservationCode={parsedReservationCode}
          />
        </TableBodyCell>
      )}
    </>
  )
}

const QuotatonDetails = ({
  clientPhone,
  quotationRegionName,
  quotationReservationCode,
  activities,
  transports,
  extraServices = []
}) => {
  const tours = useMemo(
    () =>
      [
        ...Object.values(activities || []).flat(),
        ...Object.values(transports || []).flat()
      ].filter(tour => Boolean(tour?.id)),
    [activities, transports]
  )

  const sortedTours = useMemo(() => {
    const sorted = tours.sort((a, b) => {
      const dateA = new Date(a?.date + ' ' + a?.time)
      const dateB = new Date(b?.date + ' ' + b?.time)
      return dateA.toISOString().localeCompare(dateB.toISOString())
    })
    return sorted
  }, [tours])

  const parsedExtraServices = useMemo(() => {
    const services = extraServices.map(exService => {
      const { count, slot, ticket_info, offline_experience_id, title } =
        exService
      const { price, company_name } = ticket_info
      const safeSlot = new SafeDate(slot)
      return {
        id: offline_experience_id,
        name: title,
        pax: count,
        seller: company_name,
        price,
        slot: safeSlot
      }
    })
    return services
  }, [extraServices])

  const sortedExtraServices = useMemo(() => {
    const sorted = parsedExtraServices.sort((a, b) => {
      const dateA = a?.slot.jsDate
      const dateB = b?.slot.jsDate

      return dateA.toISOString().localeCompare(dateB.toISOString())
    })
    return sorted
  }, [parsedExtraServices])

  return (
    <Card css={quotationDetailsStyle}>
      <H3>Informações do roteiro</H3>
      <div className='info__container'>
        <Row>
          <section>
            <span css={smallTitleCss}>Região: </span>
            <span>{parseTitleProp(quotationRegionName)}</span>
          </section>
          <section>
            <span css={smallTitleCss}>Código da reserva: </span>
            <span>{quotationReservationCode}</span>
          </section>
        </Row>

        <br />

        <span css={smallTitleCss}>Telefone do cliente: </span>
        <span>{clientPhone || '--'}</span>
      </div>
      <table>
        <tr>
          <th>Fornecedor</th>
          <th>Experiência</th>
          <th>Data</th>
          <th>Hora</th>
          <th>Viajantes</th>
          <th>Valor</th>
        </tr>
        {sortedTours.map(tour => (
          <tr key={tour.id}>
            <td>
              {tour?.activity?.company?.name ||
                tour?.transport?.company?.name ||
                '--'}
            </td>
            <td>
              {parseTitleProp(
                tour?.activity?.title || tour?.transport?.title || '--'
              )}
            </td>
            <td>{parseDate(tour?.date)}</td>
            <td>{tour?.time}</td>
            <td>
              <span css={peopleRowStyle}>
                {tour.adults + tour.seniors + (tour.children_ages?.length || 0)}
              </span>
            </td>
            <td>{formatMoney(tour.price)}</td>
          </tr>
        ))}
        <br />
      </table>

      {sortedExtraServices.length !== 0 && (
        <QuotationExtraServiceList extraServices={sortedExtraServices} />
      )}
    </Card>
  )
}

const TruncateCell = ({
  text = '',
  truncateStyle = undefined,
  children = null
}) => (
  <TableBodyCell colSpan={1}>
    {text ? (
      <TooltipOnHover customCss={resetPosition} text={text} position='right'>
        {' '}
        <div css={truncateStyle}>{children || text}</div>{' '}
      </TooltipOnHover>
    ) : (
      <div css={truncateStyle}>{children || text}</div>
    )}
  </TableBodyCell>
)

const CheckboxQuotation = ({ setIsOpen, isOpen, name }) => {
  return (
    <div css={checkBoxContainer}>
      <SortRightIcon
        onClick={() => setIsOpen(v => !v)}
        css={arrowIconStyle(isOpen)}
      />
      <span css={nameStyle} onClick={() => setIsOpen(v => !v)}>
        {name}
      </span>
    </div>
  )
}
